// Generated by Framer (3fa6aa4)

import { addFonts, cx, CycleVariantState, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["LRFB9sIQn"];

const serializationHash = "framer-paxIY"

const variantClassNames = {LRFB9sIQn: "framer-v-1wrbzo0"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "LRFB9sIQn", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1wrbzo0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"LRFB9sIQn"} ref={ref ?? ref1} style={{backgroundColor: "rgb(44, 8, 128)", ...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-paxIY.framer-p5ta3m, .framer-paxIY .framer-p5ta3m { display: block; }", ".framer-paxIY.framer-1wrbzo0 { height: 178px; overflow: hidden; position: relative; width: 390px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 178
 * @framerIntrinsicWidth 390
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerrYb1kIDD9: React.ComponentType<Props> = withCSS(Component, css, "framer-paxIY") as typeof Component;
export default FramerrYb1kIDD9;

FramerrYb1kIDD9.displayName = "slider bg";

FramerrYb1kIDD9.defaultProps = {height: 178, width: 390};

addFonts(FramerrYb1kIDD9, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})